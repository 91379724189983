import { getStreamsApiDepsAsync, setPonyFillDownloadCallback, type StreamsApiDependencies } from "@adra/file-system";
import { DownloadFileHandle } from "@adra/file-system/downloader";
import { ProgressStreamProcessor, StreamProgress } from "./progress-stream-processor.js";
import { IFsWritableStreamProvider } from "./providers/abstractions.js";
import { DownloadFsWritableStreamProvider } from "./providers/download.js";
import { SaveAsFsWritableStreamProvider } from "./providers/save-as.js";

type Strategy = "download" | "showSaveFilePicker";

// You may need to move this to our preferred CDN for CSP policy


export class DownloadSamplePage {

    private readonly ponyfillUrl: string =
        "https://static.adra.com/web-stream-polyfills/v3.2.1/ponyfill.es2018.min.mjs"; //"https://cdn.jsdelivr.net/npm/web-streams-polyfill@3/dist/ponyfill.es2018.mjs";

    private readonly defaultAssetUrl: string =
        `${location.protocol}//${location.host}/${encodeURIComponent("sample()'#photo copy.jpg")}`;

    private cancelButton!: HTMLButtonElement;
    private progressBar!: HTMLDivElement;

    private readonly useProgressProcessor: boolean = true;

    abortController: AbortController | null = null;

    private _progress: StreamProgress | null = null;
    get progress(): StreamProgress | null {
        return this._progress;
    }
    set progress(value: StreamProgress | null) {
        this._progress = value;
        // eslint-disable-next-line no-console
        console.log("Progress:", value);
        if (value === null) {
            this.progressBar.style.display = "none";
            this.cancelButton.style.display = "none";
        } else {
            const percent = value.totalBytes === 0 ? 0 : value.currentBytes / value.totalBytes * 100;
            (this.progressBar.children[0] as HTMLElement).style.width = `${percent}%`;
            this.progressBar.style.display = "block";
            this.cancelButton.style.display = "inline-block";
        }
    }

    async start(): Promise<void> {
        const formElem = document.querySelector("form")! as HTMLFormElement;
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        formElem.addEventListener("submit", this.onSubmit);
        (formElem["url"] as HTMLInputElement).value = this.defaultAssetUrl;

        this.cancelButton = document.getElementById("cancel-button")! as HTMLButtonElement;
        this.cancelButton.addEventListener("click", this.onDownlodCancel);
        this.progressBar = document.getElementById("progress-bar")! as HTMLDivElement;
        this.progress = null;

        const registration = await this.registerWorker();

        this.gracefullyDegrade(registration);

        // NB!!! Important to set your polyfill download function!
        setPonyFillDownloadCallback(
            () => import(/* webpackIgnore: true */ this.ponyfillUrl) as Promise<StreamsApiDependencies>,
            //true
        );
    }

    private readonly onSubmit: (ev: Event) => Promise<void> = async ev => {
        ev.preventDefault();
        const form = ev.target as HTMLFormElement;
        const strategy = (form["downloadStrategy"] as HTMLInputElement).value as Strategy;
        const url = (form["url"]! as HTMLInputElement).value;
        const filename = this.tryGetFileNameFromUrl(url);
        const preferServiceWorker = (form["preferServiceWorker"] as HTMLInputElement).checked;

        this.abortController = new AbortController();

        const response = await fetch(url, {
            headers: {
                // Set authorization headers etc
                // authorization: "Bearer token-here"
            },
            signal: this.abortController.signal
        });

        // Ensure that resposne is OK before write to disk
        if (!response.ok) {
            throw new Error(`Download failed! (response status: ${response.status}`);
        }

        const fsWritableStreamProvider = this.getFsWritableStreamProvider(strategy);

        try {
            const writableStream = await fsWritableStreamProvider.getWritableStreamAsync(
                response,
                {
                    preferServiceWorker: preferServiceWorker,
                    requestTrigger: "iframe"
                },
                filename
            );

            let readableStream: ReadableStream<Uint8Array>;

            if (!this.useProgressProcessor) {
                readableStream = response.body!;

            } else {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { TransformStream } = await getStreamsApiDepsAsync();

                let contentLength = parseInt(response.headers.get("content-length") ?? "1", 10);
                contentLength = isNaN(contentLength) ? 1 : contentLength;

                readableStream = new ProgressStreamProcessor(
                    contentLength,
                    p => this.progress = p,
                    TransformStream
                ).createReadable(
                    response.body!,
                    this.abortController.signal
                );
            }

            await readableStream.pipeTo(writableStream);

        } catch (err) {

            this.abortController.abort();

            // Errors may be thrown f.ex. if user closes saveAs dialog
            if (!(err instanceof DOMException && err.name === "AbortError")) {
                throw err;
            }
        } finally {
            this.progress = null;
            this.abortController = null;
        }

    };

    private readonly onDownlodCancel: (ev: Event) => void = e => {
        if (this.abortController) {
            this.abortController.abort();
        }
    };

    private async registerWorker(): Promise<ServiceWorkerRegistration | undefined> {
        // declaring scope manually
        let registration: ServiceWorkerRegistration | undefined;

        const c: Console = console;

        if ("serviceWorker" in navigator) {
            try {
                registration = await navigator.serviceWorker.register("/service-worker.js", {
                    scope: "/",
                    type: "module",
                    updateViaCache: "imports",
                });
                c.info("Service worker registration succeeded:", registration);
            } catch (err) {
                c.error("Service worker registration failed:", err);
            }
        } else {
            c.warn("Service workers are not supported.");
        }

        return registration;
    }

    private tryGetFileNameFromUrl(url: string): string | undefined {
        let result: string | undefined;
        const lastSegment = new URL(url).pathname.split("/").pop();
        if (lastSegment?.includes(".")) {
            result = decodeURIComponent(lastSegment);
        }
        return result;
    }

    private getFsWritableStreamProvider(strategy: Strategy): IFsWritableStreamProvider {
        switch (strategy) {
            case "download": return new DownloadFsWritableStreamProvider();
            case "showSaveFilePicker": return new SaveAsFsWritableStreamProvider();
            default: throw new Error("Unknown strategy");
        }
    }

    private gracefullyDegrade(swRegistration: ServiceWorkerRegistration | undefined): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!("showSaveFilePicker" in globalThis) || !(typeof (globalThis as any).showSaveFilePicker === "function")) {
            const radio = document.getElementById("showSaveFilePicker")! as HTMLInputElement;
            radio.disabled = true;
            const dlRadio = document.getElementById("download")! as HTMLInputElement;
            dlRadio.checked = true;

        }
        if (!swRegistration || !DownloadFileHandle.supportsServiceWorkerDownload()) {
            const checkbox = document.forms[0]["preferServiceWorker"] as HTMLInputElement;
            checkbox.disabled = true;
            checkbox.checked = false;
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises
new DownloadSamplePage().start();
