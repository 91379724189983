import { DownloadFileHandle, DownloadFileHandleServiceWorkerOptions } from "@adra/file-system/downloader";
import { IFsWritableStreamProvider } from "./abstractions.js";

export class DownloadFsWritableStreamProvider implements IFsWritableStreamProvider {
    async getWritableStreamAsync(
        response: Response,
        serviceWorkerOptions: DownloadFileHandleServiceWorkerOptions,
        filename?: string,
    ): Promise<WritableStream<Uint8Array>> {
        const fileHandle = new DownloadFileHandle({
            filename: filename,
            contentType: response.headers.get("content-type"),
            contentLength: response.headers.get("content-length"),
            serviceWorker: serviceWorkerOptions
        });
        const writableStream = await fileHandle.createWritable();
        return writableStream;
    }
}
