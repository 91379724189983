export interface StreamProgress {
    currentBytes: number;
    totalBytes: number;
}

export type RequestInitExtended = RequestInit & { duplex: "half"; };

export interface IProgressStreamProcessor {
    createReadable(stream: ReadableStream<Uint8Array>, abortSignal?: AbortSignal): ReadableStream<Uint8Array>;
}

/**
 *  Best option when the read stream is FASTER than the write stream it will be piped to (f.ex. for uploads).
 *  Requires that browser supports TransformStream - if not, you may pass ponyfill to constructor
 */
export class ProgressStreamProcessor implements Transformer<Uint8Array, Uint8Array>, IProgressStreamProcessor {
    /**
     * @param totalBytes The total number of bytes of the read stream
     * @param cbProgress The progress callback function
     * @param transformStreamType Optional ponyfill for TransformStream class
     */
    constructor(
        private readonly totalBytes: number,
        private cbProgress: (args: StreamProgress) => any,
        private readonly transformStreamType: typeof TransformStream = globalThis.TransformStream
    ) {}

    private currrentBytesRead: number = 0;
    private currrentBytesWritten: number = 0;

    createReadable(stream: ReadableStream<Uint8Array>, abortSignal?: AbortSignal): ReadableStream<Uint8Array> {
        const transformStream = new this.transformStreamType<Uint8Array, Uint8Array>(this);

        return stream.pipeThrough(transformStream, {
            ...(abortSignal ? { signal: abortSignal } : {})
        });
    }

    transform(chunk: Uint8Array, controller: TransformStreamDefaultController<Uint8Array>): void {
        controller.enqueue(chunk);
        this.currrentBytesWritten = this.currrentBytesRead;
        this.currrentBytesRead += chunk.byteLength;
        this.cbProgress({
            currentBytes: this.currrentBytesWritten,
            totalBytes: this.totalBytes
        });
    }

    flush(controller: TransformStreamDefaultController<Uint8Array>): void {
        this.currrentBytesWritten = this.currrentBytesRead;
        this.cbProgress({
            currentBytes: this.currrentBytesWritten,
            totalBytes: this.totalBytes
        });
        this.cbProgress = null!;
    }
}
