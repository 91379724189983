import { DownloadFileHandleServiceWorkerOptions, getShowSaveFilePicker, ShowSaveFilePickerTypes } from "@adra/file-system/show-save-file-picker";
import { IFsWritableStreamProvider } from "./abstractions.js";

export class SaveAsFsWritableStreamProvider implements IFsWritableStreamProvider {
    async getWritableStreamAsync(
        response: Response,
        fallbackServiceWorkerOptions: DownloadFileHandleServiceWorkerOptions,
        fileName?: string
    ): Promise<WritableStream<Uint8Array>> {

        // If filename is not specified, try to get it from the
        const extension = fileName?.split(".").pop();
        const contentType = response.headers.get("content-type");
        const types: ShowSaveFilePickerTypes[] = !contentType || !extension ? [] : [{
            accept: { [contentType]: [`.${extension}`] }
        }];

        /* eslint-disable @typescript-eslint/naming-convention */
        const showSaveFilePicker = getShowSaveFilePicker(fallbackServiceWorkerOptions);

        const fileHandle = await showSaveFilePicker({
            suggestedName: fileName,
            types: types,
            excludeAcceptAllOption: !!contentType // default
        });

        const writableStream = await fileHandle.createWritable();
        return writableStream;
    }
}

